<template>
  <Background
    :background="background"
    class="px-24 py-24 md:py-32"
    rounded="desktop"
  >
    <div
      class="m-auto flex w-full flex-col items-center gap-16 md:max-w-[736px]"
    >
      <div class="flex flex-col items-center gap-4 text-start md:text-center">
        <div v-if="title" class="heading-1">{{ title }}</div>
        <div v-if="text" class="body-1">{{ text }}</div>
      </div>
      <RevForm
        class="w-full"
        :initial-values="initialValues"
        :validate="validate()"
        @submit="onSubmit"
      >
        <template #default="{ values, errors }">
          <div class="flex flex-col gap-16">
            <div
              class="m-auto flex w-full flex-col justify-center gap-16 md:w-380 md:flex-row"
            >
              <RevInputText
                id="email-collector"
                v-model="values.email"
                class="w-full md:w-auto"
                :error="errors.email"
                :icon="IconMail"
                :label="i18n(translations.inputLabel)"
                type="email"
              />

              <div>
                <CmsButtonBase
                  class="!h-fit"
                  :disabled="submitButtonIsDisabled"
                  :loading="isLoading"
                  type="submit"
                  variant="primary"
                  width="adaptive"
                >
                  {{ buttonLabel }}
                </CmsButtonBase>
              </div>
            </div>

            <div class="m-auto">
              <RevCheckbox
                v-if="!!checkboxLabel"
                :id="`allowEmailCollection_${id}`"
                v-model="values.allowEmailCollection"
                :error="errors.allowEmailCollection"
              >
                <template #label>
                  <span class="caption">
                    {{ checkboxLabel }}

                    <RevLink v-if="pdf" target="_blank" :to="pdf.url">
                      <span class="body-1-bold"> {{ pdf.label }} </span>
                    </RevLink>
                  </span>
                </template>
              </RevCheckbox>

              <p
                v-if="errors.allowEmailCollection"
                class="text-action-danger-hi body-2 mt-4"
              >
                {{ errors.allowEmailCollection }}
              </p>
            </div>
          </div>
        </template>
      </RevForm>
    </div>
  </Background>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCheckbox } from '@ds/components/Checkbox'
import {
  RevForm,
  booleanValue,
  makeValidate,
  required,
} from '@ds/components/Form'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'
import { IconMail } from '@ds/icons/IconMail'

import Background from '../../shared-components/Background/Background.vue'
import CmsButtonBase from '../../shared-components/CmsButtonBase/CmsButtonBase.vue'

import translations from './EmailCollector.translations'
import type { EmailCollectorProps } from './EmailCollector.types'
import { useCollectEmail } from './useCollectEmail'

const props = defineProps<EmailCollectorProps>()

const initialValues = {
  email: '',
  allowEmailCollection: false,
}

const i18n = useI18n()

const { collectEmail, isLoading, submitButtonIsDisabled } = useCollectEmail()

function validate() {
  const mandatoryError = i18n(translations.formMandatoryError)
  const requiredField = required(mandatoryError)

  if (!props.checkboxLabel)
    return makeValidate<typeof initialValues>({
      email: [requiredField],
    })

  const isTrue = booleanValue(true, i18n(translations.checkboxMandatoryError))

  return makeValidate<typeof initialValues>({
    email: requiredField,
    allowEmailCollection: isTrue,
  })
}

const trackingData = computed(() => ({
  ...(props.tracking || {}),
  name: props.title,
}))
function onSubmit(values: typeof initialValues) {
  collectEmail({
    email: <string>values.email,
    trackingData: trackingData.value,
  })
}
</script>
